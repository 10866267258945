<template>
	<div class="single-view tool-tabs-to-spaces">
		<div class="tool-box">
			<h1>{{ ii('TABS_TO_SPACES') }}</h1>
			<div>
				<div class="tool-input-box max-width200">
					<s-text-field
						v-model="app.query.cnt"
						type="number"
						prepend-icon="window-minimize"
						placeholder="4"
						@change="onSpaceCntChange"
					></s-text-field>
				</div>

				<div class="tool-input-box flex">
					<s-text-area
						v-model="tabs"
						ref="tf"
						:placeholder="ii('ENTER_TEXT_WITH_TABS')"
						@input="onTabsChange"
					></s-text-area>

					<s-text-area
						v-model="spaces"
						ref="sf"
						:placeholder="ii('ENTER_TEXT_WITH_SPACES')"
						@input="onSpacesChange"
					></s-text-area>
				</div>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>
			</div>
		</div>
	</div>
</template>

<style lang="less">
	@import "../styles/helpers";

	.tool-tabs-to-spaces {
		.tool-input-box.flex {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			max-width: 700px !important;

			.text-area {
				.monospace();
				margin: 5px;
				max-width: 300px;
				min-width: 200px;

				textarea {
					resize: none;
				}
			}
		}

		.max-width200 {
			max-width: 200px !important;
		}
	}
</style>

<script>
	export default {
		data() {
			return {
				tabs: '',
				spaces: '',
			};
		},
		computed: {
			space() {
				let cnt = +(this.app.query.cnt || 4);
				if (cnt < 1 || cnt > 100) cnt = 4;

				let res = '';
				for (let i = 0; i < cnt; i++) {
					res += ' ';
				}

				return res;
			}
		},
		methods: {
			onTabsChange() {
				clearTimeout(this.debounce);
				this.debounce = setTimeout(() => {
					this.spaces = this.tabs.split('\t').join(this.space);
					this.app.query.tabs = this.tabs.length < 1000 ? this.tabs : '';
					this.app.query.spaces = '';
					this.refreshQuery();
					this.$nextTick(() => {
						this.$refs.tf.focus();
					});
				}, 300);
			},
			onSpacesChange() {
				clearTimeout(this.debounce);
				this.debounce = setTimeout(() => {
					this.tabs = this.spaces.split(this.space).join('\t');
					this.app.query.spaces = this.spaces.length < 1000 ? this.spaces : '';
					this.app.query.tabs = '';
					this.refreshQuery();
					this.$nextTick(() => {
						this.$refs.sf.focus();
					});
				}, 300);
			},
			onSpaceCntChange() {
				if (this.app.query.tabs) {
					this.onTabsChange();
				} else if (this.app.query.spaces) {
					this.onSpacesChange();
				}

				this.refreshQuery();
			}
		},
		mounted() {
			if (this.app.query.tabs) {
				this.tabs = this.app.query.tabs;
				this.onTabsChange();
			} else if (this.app.query.spaces) {
				this.spaces = this.app.query.spaces;
				this.onSpacesChange();
			} else {
				this.$refs.tf.focus();
			}
		}
	}
</script>